import React from 'react';
import './banner.css';
import RSImage from './images/RS.png';
import ChevroletImage from './images/chev.png';
import AutoHebImage from './images/red.png';
import bgMobile from './images/bgMob.png';
export default function index({ bannerType = 'small', isBackground = false, language = 'en'}){
    const handleClick = (e) => {
        console.log(e);
    }
    if(bannerType === 'small')
        return <SmallBanner isBackground={isBackground} language={language} handleClick={handleClick} />

    return <div className='mainDiv'>
        <p>test</p>
    </div>
}
const initBackground = {
    backgroundImage: {},
    videoStyle: {},
}

function SmallBanner({ language = 'en', handleClick, isBackground = false }){
    let text = '';
    let backgroundVideoStyle = isBackground ?
      {
          videoStyle: {margin: '0.2em 2em 1.6em' },
          backgroundImage: {
              backgroundImage: `url(${bgMobile})`,
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat',
          }
      } : initBackground;

    switch(language){
        case 'en':
            text = 'Find yours';
            break;
        case 'fr':
            text = 'Trouvez le votre';
            break;
    }
    return <div className='mainMobileDiv' style={backgroundVideoStyle.backgroundImage}>
        <div className='firstRow'>
            <p>INTRODUCING THE 2022 CHEVROLET</p>
            <p><img src={RSImage} alt='Chevrolet RS' onClick={handleClick} /> <span>LINEUP</span></p>
        </div>
        <div className='secondRow'>
            <div onClick={handleClick} style={{ color: 'white', ...backgroundVideoStyle.videoStyle}}>
                <div className='triangleContainer'>
                    <div className='triangle' />
                </div>
            </div>
        </div>
        <div className='thirdRow'>
            <div className='firstColumn'>
                <img src={ChevroletImage} alt='Chevrolet' onClick={handleClick} />
                <img src={AutoHebImage} alt='Auto Heb' onClick={handleClick} />
            </div>
            <button type='button' onClick={handleClick}>{text}</button>
        </div>
    </div>
}