import Banner from './components/Banner';

export default function App() {
  return (
    <div className='centeredDiv'>
      <Banner isBackground />
      <Banner isBackground language={'fr'} />
      <Banner language={'fr'} />
      <Banner />
    </div>
  );
}
